<template>
  <b-tabs class="page-tabs" v-model="tabIndex" pills>
    <b-tab>
      <template #title>
        <span>
          Click Report
        </span>
      </template>
      <div class="tab-pane__content">
        <profile-warning
          v-if="!hasClickReport"
          :buy-click-report="buyClickReport"
        />
        <profile-tabs-dashboard
          v-else
          :data="data"
        />
        <profile-actions
          :change-tab="changeTab"
        />
      </div>
    </b-tab>
    <b-tab v-if="!icsCompany">
      <template #title>
        <span>
          Business Report
        </span>
      </template>
      <div class="tab-pane__content">
        <product-table
          :list="products"
          :requestType="entityComputed"
          single-profile
          :entityType="entityTypeData"
          :profileEntity="entityData"
          context="report"
        />
      </div>
    </b-tab>
    <b-tab>
      <template #title>
        <span>
          Visure Camerali
        </span>
      </template>
      <div class="tab-pane__content">
        <product-table
          :list="products"
          :requestType="entityComputed"
          single-profile
          :entityType="entityTypeData"
          :profileEntity="entityData"
          context="visura"
        />
      </div>
    </b-tab>
    <b-tab>
      <template #title>
        <span>
          Bilanci
        </span>
      </template>
      <div class="tab-pane__content">
        <product-table
          :list="products"
          :requestType="entityComputed"
          single-profile
          :entityType="entityTypeData"
          :profileEntity="entityData"
          context="bilancio"
        />
      </div>
    </b-tab>
<!--    <b-tab>-->
<!--      <template #title>-->
<!--        <span>-->
<!--          Anagrafica-->
<!--        </span>-->
<!--      </template>-->
<!--      <div class="tab-pane__content">-->
<!--        <profile-tabs-data :data="data" />-->
<!--      </div>-->
<!--    </b-tab>-->
    <click-report-search-modal
      :id="buyClickModalId"
      :searchFunction="doClickReport"
      :searchData="{
              taxCode: taxCode,
              }"
    />
  </b-tabs>
</template>

<script>
import { isNotEmpty, validateVat } from '@/utils/validators';
import ProfileActions from '@/components/profileData/ProfileActions.vue';
import ClickReportSearchModal from '@/components/profileSearch/search/ClickReportSearchModal.vue';
import SubjectService from '@/services/subject.service';

const ProfileTabsDashboard = () => import('./ProfileTabsDashboard.vue');
// const ProfileTabsData = () => import('./ProfileTabsData.vue');
const ProductTable = () => import('@/components/productTable/ProductTable.vue');
const ProfileWarning = () => import('./ProfileWarning.vue');

export default {
  name: 'ProfileTabs',
  components: {
    ClickReportSearchModal,
    ProfileActions,
    ProfileWarning,
    // ProfileTabsCrpayReportMade,
    // ProfileTabsCrpayReportReceived,
    ProfileTabsDashboard,
    // ProfileTabsData,
    ProductTable,
  },
  props: {
    data: Object,
    entityType: String,
    taxCode: String,
  },
  data() {
    return {
      tabIndex: 0,
      icsCompany: false,
    };
  },
  computed: {
    entityComputed() {
      return isNotEmpty(this.entityType) ? `pr_${this.entityType}` : 'pr_company';
    },
    products() {
      return this.$store.getters['tableUtils/getTableList'](this.entityComputed) || [];
    },
    entityTypeData() {
      return isNotEmpty(this.entityType) ? this.entityType : '';
    },
    entityData() {
      // person available?
      if (this.entityType === 'person') {
        return {
          name: '',
          surname: '',
          taxCode: '',
          email: '',
        };
      }
      return {
        companyName: this.data?.info?.companyName,
        vatNumber: this.data?.info?.vatNumber,
        taxCode: this.data?.info?.taxCode,
        email: this.data?.attribute?.mainPecEmail?.contactData,
      };
    },
    hasScoreData() {
      return this.$store.getters['score/hasCurrentScore'];
    },
    hasClickReport() {
      return this.data?.clickReport;
    },
    isReportFull() {
      return this.data?.reportFull?.status === 'COMPLETED';
    },
    isReportInProgress() {
      return this.data?.reportFull?.status === 'IN_PROGRESS';
    },
    reportDocId() {
      return this.data?.reportFull?.documentId || null;
    },
    prePaid() {
      const wallet = this.$store.getters['wallet/getWallet'];
      if (wallet) {
        /*
        {"pr_company_other":{"productType":"pr_company_other","reportPrePaid":0,"productsCodePrePaid":{"pr_company_search":9}}}
         */
        if (wallet.productsTypePrePaid) {
          const productTypes = Object.keys(wallet.productsTypePrePaid);
          let total = 0;
          productTypes.forEach((product) => {
            const { productsCodePrePaid } = wallet.productsTypePrePaid[product];
            if (productsCodePrePaid) {
              const productCodes = Object.keys(productsCodePrePaid);
              productCodes.forEach((code) => {
                total += productsCodePrePaid[code];
              });
            }
          });
          return total;
        }
      }
      return 0;
    },
    clickPrice() {
      return this.$store.getters['wallet/getPricing']('pr_company_other', 'pr_company_click_report')?.price;
    },
    hasFund() {
      // console.log('check fund');

      const myWallet = this.$store.getters['wallet/getWallet'] || { currency: 'EUR', amount: 0 };
      // console.debug('my wallet', myWallet);
      return (myWallet.amount.amount - this.clickPrice.amount) >= 0;
    },
    hasPrepaid() {
      const myWallet = this.$store.getters['wallet/getWallet'] || { currency: 'EUR', amount: 0 };
      const { productsTypePrePaid } = myWallet;
      if (!productsTypePrePaid) return false;

      const productType = myWallet.pr_company_other;
      if (productType) {
        const clickReports = productType.pr_company_click_report || 0;
        return clickReports > 0;
      }
      return false;
    },
    buyClickModalId() {
      return 'buyClickModal';
    },
  },
  mounted() {
    this.checkIcs();
  },
  methods: {
    changeTab(tab) {
      this.tabIndex = tab;
    },
    buyClickReport() {
      console.debug('buyClickReport');
      if (!this.hasFund && !this.hasPrepaid) {
        this.$confirm(
          {
            message: "Non hai abbastanza fondi per poter procedere con l'acquisto del Click Report. Vuoi ricaricare il tuo wallet?",
            button: {
              no: 'No',
              yes: 'Si',
            },
            /**
             * Callback Function
             * @param {Boolean} confirm
             */
            callback: (confirm) => {
              if (confirm) {
                this.$router.push('/wallet-topup');
              }
            },
          },
        );
        return;
      }
      if (this.taxCode && validateVat(this.taxCode)) {
        this.$bvModal.show(this.buyClickModalId);
      }
    },
    doClickReport() {
      if (this.taxCode && validateVat(this.taxCode)) {
        const currentProfile = this.$store.getters['subject/currentCompany'];
        let ownerType;
        let ownerId;

        if (isNotEmpty(currentProfile)) {
          ownerType = 'company';
          ownerId = currentProfile.companyId;
        } else {
          ownerType = 'person';
          ownerId = this.$store.getters['subject/currentPerson'].personId;
        }

        const reportRequest = {
          ownerType,
          ownerId,
          taxCode: this.taxCode,
          companyName: '',
        };

        this.$store.dispatch('subject/clickReportCompanySearch', reportRequest)
          .then((subjects) => {
            console.log('habemus search', subjects);
            this.hasError = false;
            if (subjects.length > 0) {
              const baseUrl = this.currentContext === 'click' ? 'mf-solvency' : 'company-search';
              this.$router.push(`/${baseUrl}/company/${this.currentClickTaxCode}`);
            }
          },
          (error) => {
            console.error('Errore in ricerca click', error);
            this.hasError = true;
          });
      }
    },
    checkIcs() {
      if (this.taxCode) {
        SubjectService.checkIcsCompany(this.taxCode).then((result) => {
          console.log('check ics', this.taxCode, result.data);
          this.icsCompany = result.data;
        });
      }
    },
  },
};
</script>

<style scoped>

</style>
